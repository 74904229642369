import { ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import { BookmarkComponent, BookmarksStore } from '@aston/foundation';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';
import { Store } from '@ngrx/store';

import * as FactorFeaturesStoreSelectors from '../../../root-store/factor-features-store/selectors';

import { MenuComponent } from './menu.component';

@Component({
	selector: '[layout-menu-acc]',
	templateUrl: './menu-acc.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [TranslateModule, RouterLink, RouterLinkActive, AsyncPipe, BookmarkComponent]
})
export class MenuACCComponent extends MenuComponent implements OnInit {
	protected bookmarksStore = inject(BookmarksStore);
	private fatStore = inject(Store);
	
	isImportMenuDisabled$ = this.fatStore.select(FactorFeaturesStoreSelectors.selectIsImportMenuDisabled);
}
