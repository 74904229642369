import { filter, map, switchMap, take, throttleTime } from 'rxjs/operators';
import { ChangeDetectorRef, Component, ViewEncapsulation, inject, signal } from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Router } from '@angular/router';
import startCase from 'lodash/startCase';
import { EMPTY, merge, of, timer } from 'rxjs';

import { errorShouldBeSentToAI } from '../../functions';
import { HotkeysService } from '../../services';
import { DateFormatPipe } from '../../pipes'

import { DEBUG_BAR_CONFIG } from './debug-bar.di';

@Component({
	selector: 'debug-bar',
	templateUrl: './debug-bar.component.html',
	standalone: true,
	encapsulation: ViewEncapsulation.None,
	styles: `
		// Bug 35921: La barre de debug couvre le footer
		.with-debug-bar #content {
			padding-bottom: 40px !important;
		}
	`,
	imports: [NgIf, NgFor, NgbDropdownModule, AsyncPipe, DateFormatPipe],
})
export class DebugBarComponent {
	config = inject(DEBUG_BAR_CONFIG)
	private cd = inject(ChangeDetectorRef)
	private router = inject(Router)
	private hotkeysService = inject(HotkeysService)

	startCase = startCase;
	clipboard = navigator.clipboard;
	
	auth = this.config.auth;
	scrolled = this.config.scrollService.scrolled;
	configuration = this.config.configuration;
	featureFlags = this.config.defaultFeatureFlags?.sort();
	ffs = this.config.featureFlagsService;
	inactivityService = this.config.inactivityService;
	sessionId$ = this.config.sessionId$;
	ws = this.config.websocket;

	tenant$ = this.config.tenant$;
	tenantDesc$ = this.tenant$.pipe(filter(Boolean),map(t => `${t.id || '?'} - ${t.name}`))

	inactivityCountdown$ = this.inactivityService
		? this.inactivityService.inactivityCountdown$.pipe(throttleTime(500)) : EMPTY;
	inGracefulPeriod$ = this.inactivityService
		? this.inactivityService.inGracefulPeriod$.pipe(throttleTime(500)) : EMPTY;
	tokenTimeout$ = this.auth
		? this.auth.renewTimeBeforeTokenExpires()
		: of(0)

	tokenExpiration$ = this.auth
		? timer(0, 1000).pipe(
			switchMap(_ => this.auth.getAccessTokenExpiration()),
			map(exp => exp ? new Date(exp) : null))
		: of(new Date('3000-12-31'));

	hidden = signal(this.configuration.production)
	keybinding = merge(
		// Windows keyboard
		this.hotkeysService.addShortcut({ keys: 'control.shift.alt.x' }),
		// Mac keyboard
		this.hotkeysService.addShortcut({ keys: 'meta.shift.x' }),
	).subscribe(_ => this.hidden.set(!this.hidden()))

	errors$ = this.config.errors.events$.pipe(
		filter(error => errorShouldBeSentToAI(error)),
		map((_, index) => index + 1)
	)

	clipboardHubId() {
		this.ws.hubId$.pipe(take(1))
			.subscribe(id => navigator.clipboard.writeText(id))
	}

	toggleFeature(feature: string) {
		this.ffs.toggleFeature(feature);
		this.reloadCurrentRoute();
		this.cd.detectChanges();
	}

	reloadCurrentRoute() {
		const currentUrl = this.router.url;
		this.router
			.navigateByUrl('/', {skipLocationChange: true})
			.then(() => this.router.navigate([currentUrl]));
	}
}
