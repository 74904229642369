import { Component, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { FeatureFlagDirective } from '@aston/feature-flags';

import { MenuComponent } from './menu.component';


@Component({
	selector: '[layout-menu-its]',
	templateUrl: './menu-its.component.html',
	standalone: true,
	imports: [TranslateModule, RouterLink, RouterLinkActive, FeatureFlagDirective]
})
export class MenuITSComponent extends MenuComponent implements OnInit {

}
